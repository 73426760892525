<template>
  <div class="editSchool">
    <div class="g-section edit-wrap" v-loading="!isLoaded" element-loading-text="拼命加载中" element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)">
      <div class="g-wrap_two flex-column">
        <!-- <content-header :title-list="[]">
          <el-button slot="btn" v-if="!isdisabled" @click="isdisabled = false">编辑</el-button>
        </content-header> -->
        <div class="from-content">
          <el-button style="right: 20px;position: absolute;top: 10px;" v-show="isdisabled" @click="isdisabled = false">编辑</el-button>
          <el-form v-if="isLoaded" :model="companyForm" readonly :disabled="isdisabled" status-icon :rules="rules" ref="companyForm" label-width="110px" class="demo-companyForm">
            <el-form-item label="学校全称" prop="SchoolName">
              <el-input v-model="companyForm.SchoolName" disabled autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="学校代码" prop="Code">
              <el-input v-model="companyForm.Code" disabled placeholder="请填写信用代码" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="学校logo" prop="Logo">
              <picture-selector v-model="companyForm.Logo" name="Logo" ref="Logo" remarks="200*200" uploadPath="/DataCenterFile/UploadSingleFile" :width="100" :height="100" @input="updateForm">
              </picture-selector>
            </el-form-item>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="学校类型" prop="SchoolType">
                  <el-select v-model="companyForm.Type" placeholder="请选择" class="width100">
                    <el-option v-for="item in SchoolType" :key="item.Key" :label="item.Description" :value="item.Key">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="学校性质" prop="Nature">
                  <el-select v-model="companyForm.Nature" placeholder="请选择" class="width100">
                    <el-option v-for="item in natureOptions" :key="item.Value" :label="item.Name" :value="item.Value">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="所在区域" prop="Area">
              <el-cascader v-model="companyForm.Area" :props="props" :options="areaTree" class="width100" placeholder="请选择所属地区" @change="areaChange">
              </el-cascader>
            </el-form-item>
            <el-form-item label="详细地址" prop="Address">
              <el-input v-model="companyForm.Address" placeholder="请填写详细地址" autocomplete="off"></el-input>
            </el-form-item>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="校企合作联系人" prop="CooperationMan">
                  <el-input v-model="companyForm.CooperationMan"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="校企合作电话" prop="CooperationPhone">
                  <el-input v-model="companyForm.CooperationPhone"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row :gutter="24">
              <el-col :span="12">
                <el-form-item label="校企合作微信号" prop="CooperationWechat">
                  <el-input v-model="companyForm.CooperationWechat"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12"> </el-col>
            </el-row>
            <el-form-item label="宣传视频">
              <upload-material v-if="companyForm.Id" @input="uploadedResourse" :limit-count="1" :show-file="true" :value="companyForm.PromotionalVideo" limit-type="video/mp4" accept=".mp4" remark-text="只能上传mp4格式的视频文件">
              </upload-material>
            </el-form-item>
            <el-form-item label="学校简介">
              <rich-text v-model="companyForm.Introduction" ref="Introduction" name="Introduction" uploadPath="/DataCenterFile/UploadSingleFile" @input="updateForm"></rich-text>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="save(false)" :loading="btnLoading">保存</el-button>
              <!-- <el-button type="primary" @click="preview" plain :loading="btnLoading">预览</el-button> -->
              <el-button @click="cancel">取消</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PictureSelector from "@/components/base/PictureSelector";
import RichText from "@/components/base/RichText";
import UploadMaterial from "@/components/base/UploadChunk";
import { mapGetters } from "vuex";
import { StaffTypeEnum } from "@/utils/constant";
import { getAttributeOptions, updateEntity } from "@/api/schoolInfo";
import ContentHeader from "@/components/Common/ContentHeader";
export default {
  components: { PictureSelector, RichText, UploadMaterial, ContentHeader },
  data() {
    return {
      titleList: [
        {
          text: "修改学校信息",
          isCur: true,
          link: "",
        },
      ],
      isdisabled: true,
      isLoaded: false,
      companyForm: {
        Id: "",
        SchoolBaseId: "",
        SchoolName: "",
        BusinessLicense: "",
        Code: "",
        EstablishTime: "",
        Nature: "",
        SchoolType: "",
        City: "",
        Scale: "",
        Logo: "",
        Summary: "",
        Introduction: "",
        Area: [],
      },
      rules: {
        Name: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Position: [
          { required: true, message: "此项为必填项", trigger: "blur" },
        ],
        Email: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        EnterpriseName: [
          {
            required: true,
            message: "此项为必填项",
            trigger: ["blur", "change"],
          },
        ],
        BusinessLicense: [
          { required: true, message: "请上传营业执照", trigger: "blur" },
        ],
        IdNumber: [
          { required: true, message: "此项为必填项", trigger: "blur" },
        ],
        Code: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Logo: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        CoIndustryde: [
          { required: true, message: "此项为必填项", trigger: "blur" },
        ],
        Nature: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Industry: [
          { required: true, message: "此项为必填项", trigger: "blur" },
        ],
        Area: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Address: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        Scale: [{ required: true, message: "此项为必填项", trigger: "blur" }],
        RealName: [
          { required: true, message: "此项为必填项", trigger: "blur" },
        ],
      },
      industryOptions: [],
      natureOptions: [],
      scaleOptions: [],
      SchoolType: [],
      areaTree: [],
      props: {
        value: "Code",
        label: "Name",
        children: "Children",
      },
      btnLoading: false,
    };
  },
  computed: {
    ...mapGetters(["employee"]),
  },
  mounted() {
    getAttributeOptions().then((res) => {
      if (res.IsSuccess) {
        this.SchoolType = res.Result.schoolType;
        this.natureOptions = res.Result.natureOptions;
        this.areaTree = this.deleteChildren(res.Result.areaTree.Children);

        this.isLoaded = true;
      }
    });
    this.init();
  },
  methods: {
    init() {
      // 获取学校信息
      var company = this.employee.find((p) => p.StaffType == StaffTypeEnum.School);
      if (company) {
        this.companyForm = {...company.Employer};
        this.companyForm.Area = (
          company.Employer.ProvinceCode +
          "," +
          company.Employer.CityCode +
          "," +
          company.Employer.RegionCode
        ).split(",");
      }
    },
    updateForm(fieldName, value) {
      // console.log("修改参数:", fieldName + "值：", value);
      this.companyForm[fieldName] = value;
    },
    areaChange(value) {
      this.companyForm.ProvinceCode = value[0];
      this.companyForm.CityCode = value[1];
      this.companyForm.RegionCode = value[2];
    },
    // 保存修改
    async save(isPreview) {
      this.$refs["companyForm"].validate(async (valid) => {
        if (valid) {
          this.btnLoading = true;
          var postForm = Object.assign({}, this.companyForm);
          var res = await updateEntity(postForm);
          this.btnLoading = false;
          if (res.IsSuccess) {
            this.$message({
              message: "保存成功",
              type: "success",
            });
            if (isPreview) {
              let routeUrl = this.$router.resolve({
                path: "/cooperation/home-school/info",
                query: { id: this.companyForm.Id },
              });
              window.open(routeUrl.href, "_blank");
            } else {
              this.$router.push('/school');
            }
          } else {
            return false;
          }
        } else {
          return false;
        }
      });
    },
    async preview() {
      this.$confirm("预览前需先保存当前数据，是否继续！", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await this.save(true);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    deleteChildren(arr) {
      const childs = arr;
      for (let i = childs.length; i--; i > 0) {
        if (childs[i].Children) {
          if (childs[i].Children.length) {
            this.deleteChildren(childs[i].Children);
          } else {
            delete childs[i].Children;
          }
        }
      }
      return arr;
    },
    uploadedResourse(fileModel) {
      this.companyForm.PromotionalVideo = fileModel.Id;
    },
    cancel(){
      this.init();
      this.isdisabled=true;
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .form-header {
  background-color: #fff;
}
</style>

<style lang="scss">
.editSchool {
  width: 100%;
  height: 73vh;
  overflow: auto;
}

.g-wrap_two {
  width: 100% !important;
}

.update-body {
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  background-color: #f5f5f5;
  box-sizing: border-box;
  padding: 20px 0 24px;
  .title {
    font-size: 18px;
    width: 900px;
    text-align: left;
    line-height: 48px;
    font-weight: bold;
  }
  .update-cont {
    background-color: #fff;
    width: 900px;
    padding: 40px 60px 20px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    box-shadow: 0 3px 6px 0 #999;
    border-radius: 4px;
    min-height: 500px;
  }
  .input-text {
    color: #666;
    line-height: 40px;
    padding-left: 15px;
  }
}
.edit-wrap {
  background-color: #f5f5f5;
}
.from-content {
  background: #fff;
  border-radius: 8px;
  padding: 48px 122px;
  margin-bottom: 16px;
  min-height: 600px;
}
</style>
